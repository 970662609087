import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import Form from '../components/forms/Form'

import { StaticImage } from 'gatsby-plugin-image'

import Logos from '../components/partials/Logos9'

const upgrade = () => {
  const data = useStaticQuery(graphql`
    query UpgradeFormSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Form_Upgrade" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const onClick = () => {
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
  }

  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin mb-4">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-5 col-10 col-md-9 pt-5">
              <h1 id="heading-title">Upgrade your license</h1>
              <p className="hero-description-dark pb-0" id="d-paragraph">
                We&apos;ll get back to you as soon as possible
              </p>

              <StaticImage
                id="d-img"
                className="img-fluid mx-auto d-block"
                src="../resources/images/vectors/undraw_chatting_2yvo.svg"
                placeholder="tracedSVG"
                alt="Contact Lenses team"
              />

              <p className="hero-description-dark pt-5">
                <i
                  className="fa fa-arrow-right contact-us-arrow"
                  aria-hidden="true"
                ></i>
                <span id="bullet1">
                  For Sales Enquires:{' '}
                  <a
                    className="link-text"
                    href="mailto:sales@lenses.io"
                    id="contactus-link-salesemail"
                  >
                    sales@lenses.io
                  </a>
                </span>{' '}
                <br />
                <i
                  className="fa fa-arrow-right contact-us-arrow"
                  aria-hidden="true"
                ></i>
                <span id="bullet2">
                  For Support help:{' '}
                  <a
                    className="link-text"
                    href="mailto:support@lenses.io"
                    id="contactus-link-support"
                  >
                    support@lenses.io
                  </a>
                </span>
                <br />
                <i
                  className="fa fa-arrow-right contact-us-arrow"
                  aria-hidden="true"
                ></i>
                <span id="bullet2">
                  For General Enquiries:{' '}
                  <a
                    className="link-text"
                    href="mailto:info@lenses.io"
                    id="contactus-link-infoemail"
                  >
                    info@lenses.io
                  </a>
                </span>
              </p>
            </div>
            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      onSubmit={onClick}
                      action="https://go.pardot.com/l/877242/2020-11-27/4h3bnq"
                      inputId="formsubmit"
                      inputValue="Send Now"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                      message={'required'}
                      messageValue={
                        'Tell us about your Kafka environments & usecases'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-2 mb-5 pt-3">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md-12 text-center col-12">
              <h2 className="paragraph-title pt-5">
                Trusted by over 25,000 developers
              </h2>
            </div>
            <Logos />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default upgrade
